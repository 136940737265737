export const DE_TRANSLATIONS = {
  "SERVICE_NAME": "Bau- und Eventstrom",
  "UNAUTHORIZED": {
    "TITLE": "Kein Zugriff",
    "DESCRIPTION": "Der Service steht Ihnen leider nicht zur Verfügung.",
  },
  "ONBOARDING": {
    "TITLE": "Onboarding",
    "DESCRIPTION": "Wir berechtigen Sie für die Bau- und Eventstrom-App. In wenigen Augenblicken werden Sie zur App weitergeleitet. Dies dauert normalerweise weniger als 1 Minute.",
  },
  "VALIDATIONS": {
    "REQUIRED_FIELD": "Dies ist ein Pflichtfeld",
    "EMAIL_INVALID": "Ungültige E-Mail-Adresse",
    "POSTAL_CODE_INVALID": "Ungültige Postleitzahl",
    "PHONE_NUMBER_INVALID": "Ungültige Telefonnummer",
    "INSTALLER_NUMBER_INVALID": "Ungültige I-Nummer (gültig z.B.: I-12345-6)",
    "HOUSE_NUMBER_INVALID": "Ungültige Hausnummer",
  },
  "CURRENT_NAMES": {
    "PRODUCT": "Baustrom {{current}}",
    "EVENT": "Eventstrom {{current}}",
    "315-1": "315 - Lieferung durch BKW",
    "315-2": "315 - Lieferung durch Dritte (BKW liefert nur den Zähler)",
  },
  "BUTTONS": {
    "SAVE": "Speichern",
    "CANCEL": "Abbrechen",
    "NEXT": "Weiter",
    "BACK": "Zurück",
  },
  "COUNTRIES": {
    "SWITZERLAND": "Schweiz",
    "GERMANY": "Deutschland",
    "ITALY": "Italien",
    "FRANCE": "Frankreich",
    "AUSTRIA": "Österreich",
    "LIECHTENSTEIN": "Liechtenstein",
    "OTHER": "Anderes",
  },
  "ORDER_NOTIFICATION_MESSAGES": {
    "COMPLETE": "Besten Dank für Ihre Bestellung. Ihren Antrag stellen wir konzessionierten Elektroinstallateuren zu, welche die Installationsanzeige für den Bau- und Eventstrom zu Handen des Anschlussservice der BKW erstellen.",
    "FAILED": "Ihre Angaben sind nicht korrekt, bitte überprüfen Sie die Felder. Besten Dank",
  },
  "CONTACT_DETAILS": {
    "FORM": {
      "PLACEHOLDERS": {
        "OPTIONAL_FIELD": "(optional)",
        "CUSTOMER_NUMBER": "Kundennummer",
        "COMPANY": "Firma",
        "SALUTATION": "Anrede",
        "FIRST_NAME": "Vorname",
        "LAST_NAME": "Nachname",
        "STREET": "Strasse",
        "HOUSE_NUMBER": "Hausnummer",
        "ZIP": "PLZ",
        "CITY": "Ort",
        "COUNTRY": "Land",
        "OTHER_COUNTRY": "Bitte Land eingeben",
        "PHONE": "Telefonnummer",
        "EMAIL": "E-Mail",
      },
      "SALUTATION_OPTIONS": {
        "MALE": "Herr",
        "FEMALE": "Frau",
      },
    },
    "CHECKBOXES": {
      "OWNER": "Die Eigentümerin oder der Eigentümer entspricht dem Auftraggebenden",
      "PERSON_OF_CONSTRUCTION": "Die baustellenverantwortliche Person",
      "PERSON_OF_CONSTRUCTION_EXAMPLE": "(z. B. Polier)",
      "PERSON_OF_EVENT": "Die eventverantwortliche Person",
      "PERSON_NEUTRAL": "Die verantwortliche Person",
      "CONSTRUCTION_CONTACT": "entspricht dem Auftraggebenden",
      "SITE_MANAGER": "entspricht dem Auftraggebenden",
      "INSTALLER": "Die konzessionierten Elektroinstallateurin oder der konzessionierte Elektroinstallateur entspricht dem Auftraggebenden",
      "AUDITOR": "Der Rechnungsempfänger entspricht dem Auftraggebenden",
      "AUDITOR_HINT": "Bitte beachten Sie, dass der von Ihnen hinterlegte Rechnungsempfänger bis zur Demontage nicht mehr geändert werden kann.",
    },
  },
  "INSTALLER_COMPONENT": {
    "CHOOSE_INSTALLER": "konzessionierten Elektroinstallateurin oder konzessionierter Elektroinstallateur",
    "MANUAL": "Erfassen Sie Ihre konzessionierte Elektroinstallateurin oder ihren konzessionierten Elektroinstallateur (manuell).",
    "AUTO": "Wählen Sie eine konzessionierte Elektroinstallateurin oder einen konzessionerten Elektroinstallateur aus dem ESTI-Verzeichnis aus.",
    "LABELS": {
      "NAME": "Name",
      "FIRST_NAME": "Vorname",
      "STREET": "Strasse",
      "ZIP": "PLZ",
      "CITY": "Ort",
      "PHONE": "Tel",
      "EMAIL": "E-Mail",
    },
    "FORM": {
      "PLACEHOLDERS": {
        "I_NUMBER": "I-Nummer",
        "COMPANY": "Firma",
        "FIRST_NAME": "Vorname",
        "LAST_NAME": "Nachname",
        "PHONE": "Telefonnummer",
        "EMAIL": "E-Mail",
      },
    },
  },
  "CONNECTION_DETAILS_FORM": {
    "PLACEHOLDERS": {
      "MONTAGE_DATE": "Gewünschter Montagetermin",
      "MONTAGE_DATE_ZUSATZ": "(Start Rohbau)",
      "DEMONTAGE_DATE": "voraussichtlicher Demontagetermin",
    },
    "PRODUCTS": {
      "125": "Produkt: T125 Ampere",
      "125_CONSTRUCTION_DESCRIPTION": "Für Baustellen, z.B. im Wohnungsbau",
      "125_EVENT_DESCRIPTION": "Für Events wie Dorffeste, Schausteller, etc.",
      "315": "Produkt: T315 Ampere",
      "315_CONSTRUCTION_DESCRIPTION": "Für mittelgrosse Baustellen, z.B. im Gewerbebau / Industriebau",
      "315_EVENT_DESCRIPTION": "Für sehr grosse Events wie kantonales oder Eidgenössisches Schwingfest",
      "315_SUBOPTIONS": {
        "BKW": "Lieferung durch BKW",
        "OWN": "Lieferung durch Dritte (BKW liefert nur den Zähler)",
        "OWN_HINT": {
          "TITLE": "Technische Voraussetzungen:",
          "CONDITION_1": "Normierte Apparatetafel gemäss Werkvorschriften (A 7.62)",
          "CONDITION_2": "Für ein detailliertes Angebot kommen wir auf Sie zu",
        },
      },
      "INDIVIDUALLY": "Mittelspannungsanschluss mit mobiler Trafostation",
      "INDIVIDUALLY_DESCRIPTION": "Für grosse Baustellen, z.B. Grossindustriebau / Nationalstrassenbau",
      "INDIVIDUALLY_DESCRIPTION_2": "Das detaillierte Angebot werden wir gerne mit Ihnen zusammen ausarbeiten.",
    },
    "NOTIFICATION": {
      "MESSAGES": {
        "NOT_POSSIBLE": "Die Bestellung ist in dieser Frist nicht möglich. Bitte wählen Sie einen anderen Montagetermin.",
        "EVENT_NOT_POSSIBLE": "Die maximale Mietdauer für Eventstrom beträgt 15 Tage und ist überschritten. Bitte wählen Sie eine kürzere Mietdauer oder den Baustrom.",
        "EXPRESS": "Dies ist eine Expressbestellung und hat Kosten von 550 CHF zur Folge.",
        "NOTE": "Bitte stellen Sie sicher, dass die konzessionierte Elektroinstallateurin oder der konzessionierter Elektroinstallateur die Installationsanzeige umgehend einreicht. Stichtag für den Montagetermin ist das Eingangsdatum der Installationsanzeige beim Anschlussservice der BKW.",
      },
      "NOTE": "HINWEIS ",
    },
    "MONTAGE_DATE_HINT": "Bei Bestellungen mit Montagetermin innerhalb der nächsten vier Arbeitstagen fällt ein Expresszuschlag von 550 CHF an.",
    "SELECT_PRODUCT": "Bitte wählen Sie eines unserer Produkte aus oder geben Sie Ihre individuelle Stromstärke ein. *",
    "PRODUCT_DESCRIPTION": "Es gelten zudem unsere Bestimmungen zu den ",
    "PRODUCT_INFORMATION_URL": "http://www.bkw.ch/tempo",
    "PRODUCT_INFORMATION_TEXT": "Produkten",
    "AND": "sowie zu den",
    "PRODUCT_INFORMATION_TARIFF_URL": "http://www.bkw.ch/tarife",
    "PRODUCT_INFORMATION_TARIFF_TEXT": "Strom- und Netztarifen Grundversorgung",
  },
  "CONSTRUCTION_ADDRESS_FORM": {
    "ADDRESS": "Adresse *",
    "ADDRESS_TYPES": {
      "ADDRESS_KNOWN": "Die Bauobjektadresse ist bekannt",
      "ADDRESS_NOT_KNOWN": "Das Bauobjekt kann keiner genauen Adresse zugeordnet werden",
      "EVENT_ADDRESS_KNOWN": "Die Eventadresse ist bekannt",
      "EVENT_ADDRESS_NOT_KNOWN": "Das Event kann keiner genauen Adresse zugeordnet werden",
    },
    "PLACEHOLDERS": {
      "PROJECT_NUMBER": "Kommissions-/Projektnummer",
      "STREET": "Strasse",
      "NUMBER": "Nr",
      "ZIP": "PLZ",
      "CITY": "Ort",
      "PARCEL": "Parzellennummer",
    },
    "PROJECT_NUMBER_TOOLTIP": "Die Kommissions-/Projektnummer stellt die eindeutige Zuordnung zwischen Anmeldung und Bestellung des Bau- und Eventstrom sicher. \n\nDies kann z.B. eine Bau-, PSP- oder Angebotsnummer der Bauunternehmung sein. Diese wird für eine korrekte Zuweisung auf die Rechnung gedruckt.",
    "NO_ADDRESS": "Das Bauobjekt kann keiner genauen Adresse zugeordnet werden.",
    "NO_EVENT_ADDRESS": "Das Event kann keiner genauen Adresse zugeordnet werden.",
  },
  "POWER_CREATION_STEPPER": {
    "TITLE": "Antragsformular",
    "PRODUCT": {
      "CHOOSE_PRODUCT": "Bitte wählen Sie das gewünschte Produkt und füllen Sie Felder aus.  Das Antragsformular wird nach Abschluss Ihrer konzessionierten Elektroinstallateurin oder  Ihrem konzessionierten Elektroinstallateur zugestellt. Diese füllen die Installationsanzeige aus und stellen diese dem Anschlussservice der BKW zu.",
      "CONSTRUCTION": "Für Bauprojekte",
      "CONSTRUCTION_DESCRIPTION": "Temporärer Baustrom für Bauprojekte aller Art,  ob Einfamilienhaus oder Grossbaustellen.",
      "EVENT": "Für Events",
      "EVENT_DESCRIPTION": "Temporärer Baustrom für Feste wie Dorffest, Treberfest, Schwingfest, Turnfest oder andere Veranstaltungen.",
    },
    "LABELS": {
      "CLIENT": "Auftraggebende",
      "HINT": "Bitte beachten Sie, dass ein Bauzählerkasten nach erfolgter Installation nicht mehr auf einen anderen Kunden übertragen werden kann. Die Verantwortung obliegt bis zur Demontage des Bauzählerkastens beim Auftraggebenden.",
      "INSTALLER": "Konzessionierte Elektroinstallateurin oder konzessionierter Elektroinstallateur",
      "CONSTRUCTION_ADDRESS": "Bauobjektadresse",
      "EVENT_ADDRESS": "Eventadresse",
      "CONNECTION_DETAILS": "Anschlussdetails",
      "COMPLETION": "Abschluss",
    },
    "BUTTONS": {
      "SEND": "Antrag abschicken",
      "SAVE": "Speichern",
    },
    "CONNECTION_DETAILS": {
      "MONTAGE_DATE": "Gewünschter Montagetermin",
      "DEMONTAGE_DATE": "Geplanter Demontagetermin",
      "OTHER_AMPERAGE": "Gewünschte Stromstärke in Ampère",
      "PROVISIONALLY": "Gewünschter Anschluss: mobile Trafostation",
      "PRODUCT_COUNT": "Anzahl: ",
    },
    "INFORMATION": "Angaben zum Bauprojekt / Event",
    "AGB_TEXT_1": "Ich akzeptiere die allgemeinen Geschäftsbedingungen",
    "AGB_URL": "https://www.bkw.ch/fileadmin/user_upload/4_Ueber_BKW/Downloadcenter/AGB/Netz_und_Energie/AGB_Endverbraucher_NetzanschlussNetznutzung_A4_D_BKW_Web.pdf",
    "AGB_TEXT_2": "Netzanschluss und Netznutzung",
    "AGB_TEXT_3": ". Die darin erwähnten Werkvorschriften BE/JU/SO (",
    "AGB_URL_2": "https://www.werkvorschriften.ch",
    "AGB_TEXT_4": "www.werkvorschriften.ch",
    "AGB_TEXT_5": ") habe ich zur Kenntnis genommen.",
    "NOTE": {
      "ALL" : "Leider können wir den Antrag nicht abschicken, da zwingende Angaben fehlen. Bitte folgende Felder ausfüllen und anschliessend den Button “Antrag abschicken” wieder betätigen.",
      "ALREADY_COMPLETED": "Der Antrag wurde bereits abgeschickt. Es können keine Änderungen mehr vorgenommen werden.",
      "STEP_1": "1. Schritt:",
      "STEP_2": "2. Schritt: Konzessionierte Elektroinstallateure",
      "STEP_3": "3. Schritt:",
      "STEP_4": "4. Schritt: Anschlussdetails",
      "STEP_5": "Letzter Schritt: Abschluss",
      "INVALID_AGB": "Bitte akzeptieren Sie unsere AGBs.",
      "ADDRESS" : {
        "PROJECT_NUMBER" : "Die Projektnummer",
        "STREET" : "Die Strasse",
        "NUMBER" : "Die Nummer",
        "POSTALCODE" : "Die Postleitzahl",
        "CITY" : "Die Ortschaft",
        "PARCEL_NUMBER" : "Die Parzellennummer",
      },
      "INSTALLER": {
        "NO_INSTALLER_CHOSEN" : "Keine konzessionierte Elektroinstallateurin oder kein konzessionierter Elektroinstallateur ausgewählt!",
        "COMMISION_NUMBER" : "Die I-Nummer",
        "COMPANY" : "Die Firma",
        "FIRSTNAME" : "Der Vorname",
        "LASTNAME" : "Der Nachname",
        "TELEPHONE" : "Die Telefonnummer",
        "EMAIL" : "Die E-Mail",
      },
      "AUDITOR" : {
        "COSTUMER_NUMBER" : "Die Kundennummer vom Rechnungsempfänger",
        "COMPANY" : "Die Firma vom Rechnungsempfänger",
        "SALUTATION" : "Die Anrede vom Rechnungsempfänger",
        "FIRSTNAME" : "Der Vorname vom Rechnungsempfänger",
        "LASTNAME" : "Der Nachname vom Rechnungsempfänger",
        "STREET" : "Die Strasse vom Rechnungsempfänger",
        "HOUSENUMBER" : "Die Hausnummer vom Rechnungsempfänger",
        "POSTALCODE" : "Die Postleitzahl vom Rechnungsempfänger",
        "CITY" : "Die Ortschaft vom Rechnungsempfänger",
        "TELEPHONE" : "Die Telefonnummer vom Rechnungsempfänger",
        "EMAIL" : "Die E-Mail vom Rechnungsempfänger",
      },
      "OWNER" : {
        "COSTUMER_NUMBER" : "Die Kundennummer der Eigentümer",
        "COMPANY" : "Die Firma der Eigentümer",
        "SALUTATION" : "Die Anrede der Eigentümer",
        "FIRSTNAME" : "Der Vorname der Eigentümer",
        "LASTNAME" : "Der Nachname der Eigentümer",
        "STREET" : "Die Strasse der Eigentümer",
        "HOUSENUMBER" : "Die Hausnummer der Eigentümer",
        "POSTALCODE" : "Die Postleitzahl der Eigentümer",
        "CITY" : "Die Ortschaft der Eigentümer",
        "TELEPHONE" : "Die Telefonnummer der Eigentümer",
        "EMAIL" : "Die E-Mail der Eigentümer",
      },
      "CONSTRUCTIONCONTACT" : {
        "OF_CONSTRUCTION" : "vom Baustellenverantwortlichen",
        "OF_EVENT" : "vom Eventverantwortlichen",
        "COSTUMER_NUMBER" : "Die Kundennummer",
        "COMPANY" : "Die Firma",
        "SALUTATION" : "Die Anrede",
        "FIRSTNAME" : "Der Vorname",
        "LASTNAME" : "Der Nachname",
        "STREET" : "Die Strasse",
        "HOUSENUMBER" : "Die Hausnummer",
        "POSTALCODE" : "Die Postleitzahl",
        "CITY" : "Die Ortschaft",
        "TELEPHONE" : "Die Telefonnummer",
        "EMAIL" : "Die E-Mail",
      },
      "PRIMARYCONTACT" : {
        "COSTUMER_NUMBER" : "Die Kundennummer vom Auftraggebenden",
        "COMPANY" : "Die Firma vom Auftraggebenden",
        "SALUTATION" : "Die Anrede vom Auftraggebenden",
        "FIRSTNAME" : "Der Vorname vom Auftraggebenden",
        "LASTNAME" : "Der Nachname vom Auftraggebenden",
        "STREET" : "Die Strasse vom Auftraggebenden",
        "HOUSENUMBER" : "Die Hausnummer vom Auftraggebenden",
        "POSTALCODE" : "Die Postleitzahl vom Auftraggebenden",
        "CITY" : "Die Ortschaft vom Auftraggebenden",
        "TELEPHONE" : "Die Telefonnummer vom Auftraggebenden",
        "EMAIL" : "Die E-Mail vom Auftraggebenden",
      },
      "CONNECTIONDETAILS": {
        "INSTALL_DATE": "Der Montagetermin ist noch nicht gesetzt!",
        "DEMONTAGE_DATE": "Der Demontagetermin ist noch nicht gesetzt!",
        "INVALID_DATE": "Ungültiger Montagetermin oder Demontagetermin!",
        "PRODUCT": "Kein Produkt ausgewählt!",
      },
    },
  },
  "PRINT_STEP": {
    "LABEL": "Antragsformular drucken",
    "HAS_EMAIL": "Sie können das Antragsformular ausdrucken. Dies empfiehlt sich,  wenn Sie die Anträge in einem Ordner ablegen oder Ihren konzessionierten Elektroinstallateuren persönlich übergeben wollen.",
    "NO_EMAIL": "Sie haben bei Ihren konzessionierten Elektroinstallateuren keine E-Mail Adresse hinterlegt. Wir können den Antrag somit nicht per E-Mail zustellen. Bitte übergeben Sie den ausgefüllten Antrag  Ihren konzessionierten Elektroinstallateuren.",
    "BUTTONS": {
      "PRINT": "Drucken",
      "CLOSE": "Schliessen",
    },
  },
  "ORDER_OVERVIEW": {
    "TITLE": "Übersicht der Anträge für Bau- und Eventstrom",
    "NEW_ORDER": "Neues Antragsformular ausfüllen",
    "SEARCH_PLACEHOLDER": "Suche",
    "SEARCH_HINT": "Betätigen Sie das Suchsymbol oder die ENTER-Taste um die Suche zu starten",
    "SEARCH_HINT_2": "Nutzen Sie Anführungszeichen, um eine exakte Suche zu tätigen, z.B. \"Musterstrasse 21\".",
    "SELECT_PLACEHOLDER": "Produkt",
    "SELECT_OPTIONS": {
      "ALL": "Alle",
      "CONSTRUCTION": "Baustrom",
      "EVENT": "Eventstrom",
    },
    "DOWNLOAD_AS": "Download als",
    "CSV": "csv",
    "CSV_FILE_NAME": "Bau- und Eventstrom Anträge_",
    "IN_PROCESSING": "in Bearbeitung",
    "REQUESTED": "beantragt",
    "COLUMN_TITLES": {
      "CLIENT": "Auftraggebende",
      "PROJECT_NUMBER": "Kommissions-/Projektnummer",
      "ADDRESS": "Objektadresse",
      "PRODUCT": "Produkt",
      "SUBMISSION_DATE": "Einreiche Datum",
      "INSTALLER": "Konzessionierte Elektroinstallateurin oder konzessionierter Elektroinstallateur",
      "ASSEMBLY_DATE": "Montagetermin",
      "DISASSEMBLY_DATE": "Demontagetermin",
      "ORDER_STATUS": "Antragstatus",
    },
    "BUTTONS": {
      "COMPLETE_APPLICATION": "Antrag abschliessen",
      "APPLICATION_DETAILS": "Antragsdetails",
    },
  },
  "ORDER_DETAILS": {
    "TITLE": "Antragdetails",
    "BACK_LINK": "« Zurück zu Ihren Anträgen",
    "ERROR": "Die angeforderten Antragdetails sind nicht verfügbar.",
  },
  "ORDER_DETAILS_TABLE": {
    "EDIT_MODE": "Bearbeitungsmodus",
    "HOVER_VIEW_DOCUMENT": "Noch kein Dokument hochgeladen",
    "COLUMN_TITLES": {
      "DETAIL": "Detail",
      "STATUS": "Weitere Projektangaben",
      "DATE": "Datum",
      "RESPONSIBLE": "Verantwortlich",
      "VIEW_DOCUMENT": "Dokument<br>ansehen",
      "UPLOAD_DOCUMENT": "Dokument<br>Upload",
    },
    "ROW_NAMES": {
      "INSTALLATION_DISPLAY": "Einreichung Antrag Installationsanzeige bei Ihren konzessionierten Elektroinstallateuren",
      "INSTALLATION_CONFIRMED_DISPLAY": "Einreichung Installationsanzeige beim Anschlussservice {{companyName}}",
      "ASSEMBLY_DATE": "Montagetermin des Bauzählerkastens",
      "METER_BOX_LOCATION": "Standort Bauzählerkasten",
      "FINAL_CONTROL": "Schlusskontrolle<br>Sicherheitsnachweis mit Mess- und Prüfprotokoll",
      "LOCATION_PARCEL": "Parzellennummer",
      "LOCATION_PLAN": "Lageplan",
      "LOCATION_ADDRESS": "Bauobjektadresse",
      "EVENT_LOCATION_ADDRESS": "Eventadresse",
      "ACCEPTANCE_CONTROL": "Abnahmekontrolle",
      "DISASSEMBLY_DATE": "Demontagetermin des Bauzählerkastens <br> <br>" +
          "Wichtig! Diese Terminbestätigung löst ein Mail an Ihren Elektroinstallateur aus, <br> welcher die Abmeldung bei der BKW per Branchensoftware ElektroForm auslösen muss",
      "CLIENT": "Auftraggebende",
      "CREATED_BY": "Ausgefüllt von",
      "LAST_MODIFIED_BY": "Zuletzt geändert durch von",
      "AUDITOR": "Rechnungsempfängerin, Rechnungsempfänger",
      "INSTALLER": "Konzessionierte Elektroinstallateurin oder<br>konzessionierter Elektroinstallateur",
      "EVENT_SITE_MANAGER": "Eventverantwortliche Person",
      "SITE_MANAGER": "Baustellenverantwortliche Person",
      "OWNER": "Eigentümerin oder Eigentümer",
      "SERIAL_NUMBER": "Referenznummer des Bauzählerkastens",
    },
    "NOTIFICATION_MESSAGES": {
      "UPDATED": "Sie haben den Termin erfolgreich geändert. Bitte beachten Sie, dass der neue Termin noch nicht bestätigt ist.",
      "CONFIRMED": "Sie haben den Termin erfolgreich bestätigt",
    },
    "STATUSES": {
      "PENDING": "Terminbestätigung<br>ausstehend",
      "PENDING_2": "noch nicht eingegangen",
      "CONFIRMED": "Termin an konzessionierte Elektroinstallateur übermittelt",
      "CONFIRMED_2": "eingereicht",
    },
  },
  "EDIT_LOCATION_MAP_DIALOG": {
    "PLOT_NUMBER": "Parzelle Nr.",
    "UPLOAD_LOCATION_MAP": "Upload Lageplan Parzelle",
  },
  "EDIT_ADDRESS_DIALOG": {
    "TITLE": "Adresse - ",
    "INSTALLER": "Konzessionierte Elektroinstallateurin oder konzessionierter Elektroinstallateur",
  },
  "EDIT_CLIENT_DIALOG": {
    "CLIENT": "Auftraggebende",
  },
  "EDIT_CONSTRUCTION_POWER_BOX_DIALOG": {
    "SERIAL_NUMBER": "Referenznummer / Seriennummer Bauzählerkasten",
  },
  "EDIT_DATE_DIALOG": {
    "TITLES": {
      "FINAL_CONTROL": "Schlusskontrolle, Sicherheitsnachweis mit Mess- und Prüfprotokoll",
      "ACCEPTANCE_CONTROL": "Abnahmekontrolle",
      "INSTALLATION_CONFIRMED_DISPLAY": "Einreichung Installationsanzeige",
    },
    "PLACEHOLDERS": {
      "FINAL_CONTROL": "Schlusskontrolle Datum",
      "ACCEPTANCE_CONTROL": "Abnahmekontrolle Datum",
      "INSTALLATION_CONFIRMED_DISPLAY": "Datum der Einreichung beim Anschlussservice",
    },
  },
  "EDIT_DEMONTAGE_DATE_DIALOG": {
    "TITLE": "Termin bestätigen",
    "TEXT": "Bitte bestätigen Sie folgenden Termin oder passen Sie diesen an. Ein neuer Termin ist frühestens in 10 Arbeitstagen möglich.",
    "CONFIRMATION": "Hiermit bestätige ich, dass dies der endgültige Demontagetermin des Bauzählerkastens ist.",
    "BUTTONS": {
      "UPDATE": "Aktualisieren",
      "CONFIRM": "Bestätigen",
    },
  },
  "FILE_UPLOAD_COMPONENT": {
    "UPLOAD": "Upload Lageplan Parzelle",
    "SUCCESS": "Die Datei ist zum Upload bereit.",
    "ERROR": "Fehler beim Lesen oder Validieren der Datei. Erlaubt sind nur Bildformate JPG/JPEG und PNG oder PDFs, sowie eine maximale Dateigrösse von 2MB.",
  },
  "PDF": {
    "FILE_NAME": "_Antragsdetail Bau- und Eventstrom",
    "EXPRESS": "Express:",
    "TITLE": "Antragsformular ({{additionalData}}) Bau- und Eventstrom,",
    "NOTE": "* Der Express-Antrag ist umgehend zu bearbeiten, um eine fristgerechte Ausführung sicherzustellen.",
    "TABLE": {
      "HEADERS": {
        "CLIENT": "Auftraggebende",
        "EVENT_SITE_MANAGER": "Event- verantwortliche Person",
        "SITE_MANAGER": "Baustellen- verantwortliche Person",
        "AUDITOR": "Rechnungsemp- fängerin oder Rechnungsempfänger",
        "OWNER": "Eigentümerin oder Eigentümer",
        "INSTALLER": "Konzessionierte Elektroinstallateurin oder konzessionierter Elektroinstallateur",
        "PROJECT_ADDRESS": "Bauobjekteadresse",
        "EVENT_ADDRESS": "Eventadresse",
        "LOCATION_MAP": "Lageplan Parzelle",
        "CONNECTION_DETAILS": "Anschlussdetails",
      },
      "ASSEMBLY_DATE": "Gewünschter Montagetermin:",
      "DISASSEMBLY_DATE": "Voraussichtlicher Demontagetermin:",
      "PRODUCT": "Produkt:",
      "PRODUCT_COUNT": "Anzahl:",
      "COMMISSION_NUMBER": "Kommissions-/Projektnummer:",
      "STREET_NUMBER": "Strasse, Nr:",
      "ZIP_CITY": "PLZ, Ort:",
      "PLOT_NUMBER": "Parzellennummer:",
      "I_NUMBER": "Bewilligungsnummer:",
      "REPLACEMENT_LICENSE": "Ersatzbewilligung:",
      "COMPANY": "Firma:",
      "NAME": "Name:",
      "PHONE": "Tel:",
      "EMAIL": "E-Mail:",
      "JA": "Ja",
      "NEIN": "Nein",
    },
  },
  "TEMPORARY_TRANSFORMER_STATION": "Provisorische Trafostation",
};
