import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { BfcAuthenticationService } from "@bfl/components/authentication";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Injectable()
export class AuthGuardTempo implements CanActivate {

  constructor(private bfcAuthenticationService: BfcAuthenticationService,
    private bfcConfigurationService: BfcConfigurationService,
    private router: Router) {

  }

  canActivate() {
    const isUser: boolean =
      this.bfcAuthenticationService.hasRealmRole(this.bfcConfigurationService.configuration.userRole);
    const isAdmin: boolean =
      this.bfcAuthenticationService.hasRealmRole(this.bfcConfigurationService.configuration.adminRole);

    if (!isUser && !isAdmin) {
      this.router.navigate(["/unauthorized"], { skipLocationChange: true });
      return false;
    }
    return true;
  }

  isUrlAllowed(url: string): boolean {
    return  url.startsWith(this.bfcConfigurationService.configuration.siteUrl);
  }

}