import { RouterModule, Routes } from "@angular/router";
import { AuthGuardTempo } from "./core/auth/auth-guard-tempo.service";
import { OnboardingComponent } from "./core/onboarding/onboarding.component";
import { NgModule } from "@angular/core";
import { translations } from "./config/translations/translations";

const routes: Routes = [
  { path: "", redirectTo: "order", pathMatch: "full" },
  { path: "order", canActivate: [AuthGuardTempo], loadChildren: () => import ("./request-creation/request-creation.module").then(m => m.RequestCreationModule) },
  { path: "unauthorized",     loadChildren: () => import("@bfl/components/unauthorized").then(m => m.BfcUnauthorizedModule.asChild(translations)) },
  { path: "onboarding", component: OnboardingComponent },
  { path: "**", redirectTo: "error" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}