import { IBfcConfiguration } from "@bfl/components/configuration";
import { GENERAL_ENV } from "./environments/general";
import { LOCAL_ENV } from "./environments/local";
import { T_ENV } from "./environments/t";
import { Q_ENV } from "./environments/q";
import { P_ENV } from "./environments/p";

export const configuration: IBfcConfiguration[] = [{
  theme: "bkw",
  general: GENERAL_ENV,
  local: LOCAL_ENV,
  t: T_ENV,
  q: Q_ENV,
  p: P_ENV,
}];

export interface AppConfiguration {
  apiUrl?: string;
  installerApiUrl?: string;
  tempoGrantUrl?: string;
  opSelfServiceApiUrl?: string;
  postCodesApiUrl?: string;
  userRole?: string;
  adminRole?: string;
  siteUrl?: string;
  companyName?: string;
  personaRoles?: PersonaRoles;
  uploadConfigs?: UploadConfiguration;
}

export interface PersonaRoles {
  adminRole: string;
  creatorRole: string;
  clientRole: string; // Auftraggeber
}

export interface UploadConfiguration {
  allowedExtensions?: string; // comma separated
  maxFileSize?: number; // in bytes, e.g.: 2000000 = 2 MB
}