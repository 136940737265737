import { Injectable } from "@angular/core";
import { BfcAuthenticationService } from "@bfl/components/authentication";
import { BfcTranslationService } from "@bfl/components/translation";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { AccountService } from "../service/account.service";
import { UrlHelperService } from "../service/url.helper.service";
import { AuthGuardTempo } from "../auth/auth-guard-tempo.service";
import { Title } from "@angular/platform-browser";

@Injectable()
export class InitializerProvider {

  private readonly searchParam: string = "comesFrom";

  constructor(private bfcTranslateService: BfcTranslationService,
    private bfcConfigurationService: BfcConfigurationService,
    private bfcAuthenticationService: BfcAuthenticationService,
    private accountService: AccountService,
    private urlHelperService: UrlHelperService,
    private authGuard: AuthGuardTempo,
    private title: Title) {

  }

  public initialize() {
    return new Promise((resolve) => {

      const serviceName: string = this.bfcTranslateService.translate("SERVICE_NAME");

      this.title.setTitle(serviceName);

      this.grantAccountIfComesFromLandingpage(resolve); // resolve() will be done inside this method
    });
  }

  private grantAccountIfComesFromLandingpage(resolve: any) {
    const parameterIsPresent: boolean = this.isParameterPresent();

    if (parameterIsPresent) {
      // we come from landingpage, so check for ROLE_TEMPO / ROLE_TEMPO_ADMIN, if not present, grant the user
      const isUser: boolean =  this.bfcAuthenticationService
        .hasRealmRole(this.bfcConfigurationService.configuration.userRole);
      const isAdmin: boolean = this.bfcAuthenticationService
        .hasRealmRole(this.bfcConfigurationService.configuration.adminRole);

      if (!isUser && !isAdmin) {
        // if we got parameter "comesFrom" and value "tempo-landingpage", then grant the user:
        this.accountService.grantMyAccount()
          .subscribe((succeeded: boolean) => {
            if (succeeded) {
              const targetUrl =  window.location.href;
              if ( this.authGuard.isUrlAllowed(targetUrl)){
                window.location.href = "/onboarding?from=" + encodeURIComponent(targetUrl); // to force reload / refresh token
              }
              resolve();
            }
          },
          );
      }  else {
        resolve();
      }
    } else {
      resolve();
    }

  }

  private isParameterPresent(): boolean {
    const paramValue = this.urlHelperService.getParameterValue(this.searchParam);
    return paramValue && (paramValue === "tempo-landingpage" || paramValue.startsWith("tempo-landingpage"));
  }
}
