import { AppConfiguration } from "../configuration";

export const Q_ENV: AppConfiguration = {
  opSelfServiceApiUrl: "https://api-op-self-service-q.bkw.ch",
  apiUrl: "https://api-tempo-q.bkw.ch/tempo",
  installerApiUrl: "https://api-esti-q.bkw.ch/installers",
  tempoGrantUrl: "https://api-tempo-q.bkw.ch/account/grant",
  postCodesApiUrl: "https://eai-swisspost-functions-qa.azurewebsites.net/api/v1/Postcodes?code=uyKaO4SZnaYql8UD4YJS0ms5FY9s/JYbOeTgNRVNzfwIjqxg7vS7bA==",
  siteUrl: "https://tempo-gridconnection-q.bkw.ch",
};
