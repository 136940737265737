import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable } from "rxjs";
import { PostCode } from "../model/post-code.interface";
import { map } from "rxjs/operators";

@Injectable()
export class PostCodesService {

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService) {
  }

  public getLocations(postCode: number): Observable<string[]> {
    return this.httpClient.get<PostCode[]>(`${this.bfcConfigurationService.configuration.postCodesApiUrl}&postcode=${postCode}`).pipe(map((postCodes: PostCode[]) => {
      let postCodeLocations: string[] = [];
      postCodes.forEach((postcode: PostCode) => {
        postCodeLocations.push(postcode.Location);
      });
      return postCodeLocations;
    }));
  }

}
