import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class AccountService {

  public constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService) {
  }

  public grantMyAccount(): Observable<boolean> {
    return this.httpClient.post(this.bfcConfigurationService.configuration.tempoGrantUrl, null)
      .pipe(
        catchError(() => {
          return of(false);
        }),
        map((result) => !result || !!result), // if return null or true -> return true.
      );
  }
}