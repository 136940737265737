import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BfcTranslationModule } from "@bfl/components/translation";
import { OnboardingComponent } from "./onboarding.component";

@NgModule({
  imports: [
    BfcTranslationModule,
    FlexLayoutModule,
    CommonModule,
  ],

  declarations: [
    OnboardingComponent,
  ],
})
export class OnboardingModule {
}