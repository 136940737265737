import { HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { CoreModule } from "../core.module";
import { CacheEntry } from "./cache-entry";

@Injectable({
  providedIn: CoreModule,
})
export class RequestCacheService  {
  private cache: Map<string, CacheEntry> = new Map();
    
  public get(request: HttpRequest<any>): HttpResponse<any> | undefined {
    const encodedUrl: string = encodeURI(request.urlWithParams);
    const cached = this.cache.get(encodedUrl);
        
    if (!cached) {
      return undefined;
    }
        
    return cached.response;
  }
    
  public put(request: HttpRequest<any>, response: HttpResponse<any>): void {
    const encodedUrl: string = encodeURI(request.urlWithParams);
        
    const entry: CacheEntry = { 
      url: encodedUrl, 
      response, 
    };

    this.cache.set(encodedUrl, entry);
  }
}