import { Injectable } from "@angular/core";
import { BfcTranslationService } from "@bfl/components/translation";
import { AbstractControl } from "@angular/forms";
import { PatternConstants } from "./pattern.constants";

@Injectable()
export class ValidationErrorMessageService {

  constructor(private bfcTranslationService: BfcTranslationService) {
  }

  validationErrorMessage(formControl: AbstractControl): string {
    let errorMessageKey = "";
    if (formControl.hasError("required")) {
      errorMessageKey = "VALIDATIONS.REQUIRED_FIELD";
    } else if (formControl.hasError("pattern")) {
      const requiredPattern = formControl.getError("pattern").requiredPattern;
      switch (requiredPattern) {
        case PatternConstants.POSTAL_CODE_PATTERN:
          errorMessageKey = "VALIDATIONS.POSTAL_CODE_INVALID";
          break;
        case PatternConstants.PHONE_NUMBER_PATTERN:
          errorMessageKey = "VALIDATIONS.PHONE_NUMBER_INVALID";
          break;
        case PatternConstants.INSTALLER_NUMBER_PATTERN:
          errorMessageKey = "VALIDATIONS.INSTALLER_NUMBER_INVALID";
          break;
        case PatternConstants.HOUSE_NUMBER_PATTERN:
          errorMessageKey = "VALIDATIONS.HOUSE_NUMBER_INVALID";
          break;
      }
    } else if (formControl.hasError("email")) {
      errorMessageKey = "VALIDATIONS.EMAIL_INVALID";
    }

    return this.bfcTranslationService.translate(errorMessageKey);
  }

}
