import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from "@angular/core";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { InitializerProvider } from "./initialization/initializer-provider";
import { initalizerFactory } from "./initialization/initializer-factory";
import { HttpClientModule } from "@angular/common/http";
import { OpSelfService } from "./service/opselfservice.service";
import { AuthGuardTempo } from "./auth/auth-guard-tempo.service";
import { ValidationErrorMessageService } from "./validation-error-message/validation-error-message.service";
import { FileReaderService } from "./file-reader/file-reader.service";
import { PostCodesService } from "./service/post-codes.service";
import { MomentHelperService } from "./service/moment-helper.service";
import { AccountService } from "./service/account.service";
import { UrlHelperService } from "./service/url.helper.service";

@NgModule({
  imports: [HttpClientModule],
  exports: [],
  declarations: [],
  providers: [
    InitializerProvider,
    AuthGuardTempo,
    AccountService,
    UrlHelperService,
    {
      provide: APP_INITIALIZER,
      useFactory: initalizerFactory,
      deps: [InitializerProvider],
      multi: true,
    },
    OpSelfService,
    ValidationErrorMessageService,
    FileReaderService,
    PostCodesService,
    MomentHelperService,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
