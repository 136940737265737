import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Inject, Injectable, Optional } from "@angular/core";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";

import { CACHING_INTERCEPTOR_WHITELIST } from "./caching-injection-token";
import { RequestCacheService } from "./request-cache.service";

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
    
  constructor(private cache: RequestCacheService,
    @Inject(CACHING_INTERCEPTOR_WHITELIST) @Optional() private cacheInterceptorWhitelist: RegExp[]) {
  }
    
  public intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (
      this.cacheInterceptorWhitelist &&
      this.cacheInterceptorWhitelist.some(entry => !!request.urlWithParams.match(entry))
    ){
      const cachedResponse = this.cache.get(request);
      return cachedResponse ? of(cachedResponse) : this.sendRequest(request, next, this.cache);
    } else {
      return next.handle(request);
    }
  }
    
  public sendRequest(
    request: HttpRequest<any>,
    next: HttpHandler,
    cache: RequestCacheService,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          cache.put(request, event);
        }
      }),
    );
  }
}