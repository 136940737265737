import { Component } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";

@Component({
  selector: "app-component",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {

    this.matIconRegistry.addSvgIcon(
      "apero",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/BKW_Icon_Apero_Tile.svg"),
    );

    this.matIconRegistry.addSvgIcon(
      "safety",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/BKW_Icon_Safety_Tile.svg"),
    );

    this.matIconRegistry.addSvgIcon(
      "company",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/BKW_Icon_Company.svg"),
    );

    this.matIconRegistry.addSvgIcon(
      "home",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/BKW_Icon_Home.svg"),
    );

    this.matIconRegistry.addSvgIcon(
      "delete",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/BKW_Icon_Delete.svg"),
    );

    this.matIconRegistry.addSvgIcon(
      "document-search",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/BKW_Icon_Document_Search.svg"),
    );
  }

}
