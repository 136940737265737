import { AppConfiguration } from "../configuration";

export const T_ENV: AppConfiguration = {
  opSelfServiceApiUrl: "https://api-op-self-service-t.bkw.ch",
  apiUrl: "https://api-tempo-t.bkw.ch/tempo",
  installerApiUrl: "https://api-esti-t.bkw.ch/installers",
  tempoGrantUrl: "https://api-tempo-t.bkw.ch/account/grant",
  postCodesApiUrl: "https://eai-swisspost-functions-test.azurewebsites.net/api/v1/Postcodes?code=uyKaO4SZnaYql8UD4YJS0ms5FY9s/JYbOeTgNRVNzfwIjqxg7vS7bA==",
  siteUrl: "https://tempo-gridconnection-t.bkw.ch",
};
