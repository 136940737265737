import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BFC_TRANSLATIONS, BfcTranslationModule } from "@bfl/components/translation";
import { translations } from "./config/translations/translations";
import { BFC_CONFIGURATIONS, BfcConfigurationModule } from "@bfl/components/configuration";
import { BfcGoogleAnalyticsModule } from "@bfl/components/google-analytics";
import { configuration } from "./config/configuration";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { OverlayModule } from "@angular/cdk/overlay";
import { BfcNotificationModule } from "@bfl/components/notification";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import {
  BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
  BfcAuthenticationInterceptor,
  BfcAuthenticationModule,
} from "@bfl/components/authentication";
import { authenticationInterceptorWhitelist } from "./config/authentication-interceptor-whitelist";
import { AppRoutingModule } from "./app-routing.module";
import { OnboardingModule } from "./core/onboarding/onboarding.module";
import { cachingInterceptorWhitelist } from "./config/caching-interceptor-whitelist";
import { CACHING_INTERCEPTOR_WHITELIST } from "./core/caching/caching-injection-token";
import { CachingInterceptor } from "./core/caching/caching-interceptor.service";
import { BfcSinglePageLayoutModule } from "@bfl/components/single-page-layout";
import { BfcSinglePageLayoutLargeModule } from "@bfl/components/single-page-layout-large";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    HttpClientModule,
    BfcTranslationModule,
    BfcGoogleAnalyticsModule,
    BfcConfigurationModule,
    BfcNotificationModule,
    BfcAuthenticationModule,
    BfcSinglePageLayoutModule,
    CoreModule,
    SharedModule,
    OverlayModule,
    AppRoutingModule,
    OnboardingModule,
    BfcSinglePageLayoutLargeModule,
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BfcAuthenticationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CachingInterceptor,
      multi: true,
    },
    {
      provide: BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
      useValue: authenticationInterceptorWhitelist,
    },
    {
      provide: CACHING_INTERCEPTOR_WHITELIST,
      useValue: cachingInterceptorWhitelist,
    },
    {
      provide: BFC_TRANSLATIONS,
      useValue: translations,
      multi: true,
    },
    {
      provide: BFC_CONFIGURATIONS,
      useValue: configuration,
      multi: true,
    },
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {
}
