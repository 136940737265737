import { Injectable } from "@angular/core";
import { Moment } from "moment";
import * as moment from "moment";

@Injectable()
export class MomentHelperService {

  public addWeekdays(date: Moment, days: number): Moment {

    while (days > 0) {
      date.add(1, "days");
      if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
        days -= 1;
      }
    }

    return date;
  }

  public addMonths(date: Moment, months: number): Moment {
    return date.add(months, "months");
  }

  public isDateInFuture(date: Moment): boolean {
    return date && moment(0, "HH").diff(date, "days") >= 0;
  }
}
