import { Component, OnInit } from "@angular/core";
import { UrlHelperService } from "../service/url.helper.service";
import { AuthGuardTempo } from "../auth/auth-guard-tempo.service";

@Component({
  selector: "app-onboarding-component",
  templateUrl: "onboarding.component.html",
})
export class OnboardingComponent implements OnInit {

  constructor(private urlHelperService: UrlHelperService,
    private authGuard: AuthGuardTempo) {
  }

  ngOnInit(): void {
    const fromParam = this.urlHelperService.getParameterValue("from");
    const decodedUrl = fromParam ? decodeURIComponent(fromParam) : "";

    if (decodedUrl && decodedUrl.indexOf("/onboarding") < 0 && this.authGuard.isUrlAllowed(decodedUrl)){
      // wait 35s beaause of self-service caching
      setTimeout(() =>{
        window.location.href = decodedUrl.replace("fromUrl=", "oldFromUrl=").replace("comesFrom=", "").replace("tempo-landingpage", "");
      }, 35000);
    }
  }
}
