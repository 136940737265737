import { AppConfiguration } from "../configuration";

export const GENERAL_ENV: AppConfiguration = {
  userRole: "ROLE_TEMPO",
  adminRole: "ROLE_TEMPO_ADMIN",
  companyName: "BKW",
  personaRoles: {
    adminRole: "TEMPO.ADMIN",
    creatorRole: "TEMPO.CREATOR",
    clientRole: "TEMPO.CLIENT",
  },
  uploadConfigs: {
    allowedExtensions: ".jpg,.png,.jpeg,.pdf",
    maxFileSize: 2000000, // 2MB
  },
};