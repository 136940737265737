import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Account } from "../../generated/op-self-service/model/account";
import { Address } from "../../generated/op-self-service/model/address";
import { Organisation } from "../../generated/op-self-service/model/organisation";
import { Person } from "../../generated/op-self-service/model/person";
import { Service } from "../../generated/op-self-service/model/service";
import { EmbeddedResourceWrapper } from "../../shared/model/embedded-resource-wrapper";

@Injectable()
export class OpSelfService {
  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService) {
  }

  public getAccount(): Observable<Account> {
    return this.httpClient.get<Account>(this.bfcConfigurationService.configuration.opSelfServiceApiUrl + "/me");
  }

  public updateAccount(account: Partial<Account>): Observable<Account> {
    return this.httpClient.patch<Account>(this.bfcConfigurationService.configuration.opSelfServiceApiUrl + "/me", account);
  }

  public getPersonWithAddresses(): Observable<Person> {
    return this.httpClient.get<Person>(this.bfcConfigurationService.configuration.opSelfServiceApiUrl + "/me/person", this.getExpandAddressOptions());
  }

  public updatePerson(person: Partial<Person>): Observable<Person> {
    return this.httpClient.patch<Person>(this.bfcConfigurationService.configuration.opSelfServiceApiUrl + "/me/person", person);
  }

  public getUserOrganisations(): Observable<Organisation[]> {
    return this.httpClient.get<EmbeddedResourceWrapper<Organisation>>(
      this.bfcConfigurationService.configuration.opSelfServiceApiUrl + "/me/organisations", this.getExpandAddressOptions(),
    ).pipe(
      map((resources: EmbeddedResourceWrapper<Organisation>) => {
        return !resources.isEmpty("organisations") ? resources.getCollection("organisations") : [];
      }),
    );
  }

  public getUserOrganisation(organisationId: number): Observable<Organisation> {
    return this.httpClient.get<Organisation>(
      this.bfcConfigurationService.configuration.opSelfServiceApiUrl + "/me/organisations/" + organisationId, this.getExpandAddressOptions(),
    );
  }

  public getUserOrganisationServices$(organisationId: number): Observable<Service[]> {
    return this.httpClient.get<EmbeddedResourceWrapper<Service>>(this.bfcConfigurationService.configuration.opSelfServiceApiUrl + "/me/organisations/" + organisationId + "/services")
      .pipe(
        map((resources: EmbeddedResourceWrapper<Service>) => {
          return !resources.isEmpty("services") ? resources.getCollection("services") : [];
        }),
      );
  }

  public createUserAddress(address: Address): Observable<Address> {
    return this.httpClient.post<Address>(this.bfcConfigurationService.configuration.opSelfServiceApiUrl + "/me/addresses", address);
  }

  public updateUserAddress(address: Partial<Address> & { addressId: number }): Observable<Address> {
    return this.httpClient.patch<Address>(this.bfcConfigurationService.configuration.opSelfServiceApiUrl + "/me/addresses/" + address.addressId, address);
  }

  public deleteUserAddress(addressId: number): Observable<void> {
    return this.httpClient.delete<void>(this.bfcConfigurationService.configuration.opSelfServiceApiUrl + "/me/addresses/" + addressId);
  }

  public getAllServicesForUser(): Observable<Service[]> {
    return this.httpClient.get<EmbeddedResourceWrapper<Service>>(this.bfcConfigurationService.configuration.opSelfServiceApiUrl + "/me/services/all").pipe(
      map((resources: EmbeddedResourceWrapper<Service>) => {
        return !resources.isEmpty("services") ? resources.getCollection("services") : [];
      }),
      catchError(() => of([])),
    );
  }

  private getExpandAddressOptions() {
    return { params: new HttpParams().set("expandAddresses", "true") };
  }

}