import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Injectable()
export class FileReaderService {

  constructor(private configService: BfcConfigurationService) {
  }

  public readFile(file: File, maxSize): Observable<string> {
    if (file.size > maxSize) {
      return of(null);
    }

    const splitted = file.name.split(".");
    if (splitted.length < 2){
      return of(null);
    }
    const allowedExtensions = this.configService.configuration.uploadConfigs.allowedExtensions.split(",");
    if (!allowedExtensions.includes("." + splitted[1])){
      return of(null);
    }

    let fileReader: FileReader = new FileReader();
    const subject = new Subject<string>();

    fileReader.onloadend = () => {
      subject.next(fileReader.result as string);
      subject.complete();
    };

    fileReader.onerror = (e) => {
      subject.error(e);
    };

    fileReader.onabort = (e) => {
      subject.error(e);
    };

    fileReader.readAsDataURL(file);

    return subject;
  }
}
