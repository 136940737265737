export const FR_TRANSLATIONS = {
  "SERVICE_NAME": "Électricité temporaire",
  "UNAUTHORIZED": {
    "TITLE": "Pas d’accès",
    "DESCRIPTION": "Vous n’avez malheureusement pas accès au service.",
  },
  "ONBOARDING": {
    "TITLE": "Onboarding",
    "DESCRIPTION": "Nous vous autorisons à utiliser l’application d’électricité temporaire. La redirection vers l’application aura lieu dans quelques instants. L’opération prend normalement moins d’une minute.",
  },
  "VALIDATIONS": {
    "REQUIRED_FIELD": "Ce champ est obligatoire",
    "EMAIL_INVALID": "Adresse e-mail non valable",
    "POSTAL_CODE_INVALID": "NPA non valable",
    "PHONE_NUMBER_INVALID": "Numéro de téléphone non valable",
    "INSTALLER_NUMBER_INVALID": "Numéro I non valable (valable par example: I-12345-6)",
    "HOUSE_NUMBER_INVALID": "Numéro de maison non valable",
  },
  "CURRENT_NAMES": {
    "PRODUCT": "Électricité du chantier {{current}}",
    "EVENT": "Électricité de l'événement {{current}}",
    "315-1": "315 - Livraison par BKW",
    "315-2": "315 - Livraison par tiers (BKW fournit uniquement le compteur)",
  },
  "BUTTONS": {
    "SAVE": "Enregistrer",
    "CANCEL": "Annuler",
    "NEXT": "Suivant",
    "BACK": "Retour",
  },
  "COUNTRIES": {
    "SWITZERLAND": "Suisse",
    "GERMANY": "Allemagne",
    "ITALY": "Italie",
    "FRANCE": "France",
    "AUSTRIA": "Autriche",
    "LIECHTENSTEIN": "Liechtenstein",
    "OTHER": "Autres",
  },
  "ORDER_NOTIFICATION_MESSAGES": {
    "COMPLETE": "Merci pour votre commande. Nous transmettons votre demande aux installateurs-électriciens sous concession qui établiront l’avis d’installation pour l’électricité temporaire à l’intention au service de raccordement de BKW.",
    "FAILED": "Vos données sont incorrectes, veuillez vérifier les champs. Merci beaucoup",
  },
  "CONTACT_DETAILS": {
    "FORM": {
      "PLACEHOLDERS": {
        "OPTIONAL_FIELD": "(optionnel)",
        "CUSTOMER_NUMBER": "N° client",
        "COMPANY": "Entreprise",
        "SALUTATION": "Formule de civilité",
        "FIRST_NAME": "Prénom",
        "LAST_NAME": "Nom",
        "STREET": "Rue",
        "HOUSE_NUMBER": "Numéro",
        "ZIP": "NPA",
        "CITY": "Localité",
        "COUNTRY": "Pays",
        "OTHER_COUNTRY": "Veuillez indiquer le pays",
        "PHONE": "No de téléphone",
        "EMAIL": "E-Mail",
      },
      "SALUTATION_OPTIONS": {
        "MALE": "Monsieur",
        "FEMALE": "Madame",
      },
    },
    "CHECKBOXES": {
      "OWNER": "La ou le propriétaire correspond au mandant",
      "PERSON_OF_CONSTRUCTION": "La personne responsable du chantier",
      "PERSON_OF_CONSTRUCTION_EXAMPLE": "(le contremaître par exemple)",
      "PERSON_OF_EVENT": "La personne responsable de l'événement",
      "PERSON_NEUTRAL": "La personne responsable",
      "CONSTRUCTION_CONTACT": "correspond au mandant",
      "SITE_MANAGER": "correspond au mandant",
      "INSTALLER": "L’installatrice-électricienne ou l’installateur-électricien sous concession correspond au mandant",
      "AUDITOR": "Le destinataire de la facture correspond au mandant",
      "AUDITOR_HINT": "Veuillez noter que le destinataire de la facture que vous avez renseigné ne pourra pas être modifié jusqu'à la démontage.",
    },
  },
  "INSTALLER_COMPONENT": {
    "CHOOSE_INSTALLER": "Installatrice-électricienne ou installateur-électricien sous concession",
    "MANUAL": "Veuillez saisir (manuellement) votre installatrice-électricienne ou installateur-électricien sous concession.",
    "AUTO": "Sélectionnez une installatrice-électricienne ou un installateur-électricien sous concession dans l’annuaire de l’ESTI.",
    "LABELS": {
      "NAME": "Nom",
      "FIRST_NAME": "Prénom",
      "STREET": "Rue",
      "ZIP": "NPA",
      "CITY": "Localité",
      "PHONE": "Tél.",
      "EMAIL": "E-Mail",
    },
    "FORM": {
      "PLACEHOLDERS": {
        "I_NUMBER": "Numéro I",
        "COMPANY": "Entreprise",
        "FIRST_NAME": "Prénom",
        "LAST_NAME": "Nom",
        "PHONE": "No de téléphone",
        "EMAIL": "E-Mail",
      },
    },
  },
  "CONNECTION_DETAILS_FORM": {
    "PLACEHOLDERS": {
      "MONTAGE_DATE": "Date de montage souhaitée",
      "MONTAGE_DATE_ZUSATZ": "(début du gros œuvre)",
      "DEMONTAGE_DATE": "Date de démontage prévue",
    },
    "PRODUCTS": {
      "125": "Produit: T125 ampères",
      "125_CONSTRUCTION_DESCRIPTION": "Pour des chantiers, par exemple pour la construction de logements",
      "125_EVENT_DESCRIPTION": "Pour des événements tels que des fêtes de village, des fêtes foraines, etc.",
      "315": "Produit: T315 ampères",
      "315_CONSTRUCTION_DESCRIPTION": "Pour des chantiers de taille moyenne, par exemple pour la construction de commerces ou de sites industriels",
      "315_EVENT_DESCRIPTION": "Pour des événements de très grande envergure tels que des fêtes cantonales ou la fête fédérale de lutte suisse",
      "315_SUBOPTIONS": {
        "BKW": "Livraison par BKW",
        "OWN": "Livraison par tiers (BKW fournit uniquement le compteur)",
        "OWN_HINT": {
          "TITLE": "Conditions techniques:",
          "CONDITION_1": "Panneaux normalisé selon PDIE (A 7.62)",
          "CONDITION_2": "Équipement de mesure avec transformateurs d’intensité 3x400/230 existant",
        },
      },
      "INDIVIDUALLY": "Raccordement moyenne tension avec station transformatrice mobile",
      "INDIVIDUALLY_DESCRIPTION": "Pour des chantiers de grande envergure tels que la construction de grands sites industriels ou de routes nationales",
      "INDIVIDUALLY_DESCRIPTION_2": "Nous élaborons volontiers l’offre détaillée avec vous.",
    },
    "NOTIFICATION": {
      "MESSAGES": {
        "NOT_POSSIBLE": "La commande n’est pas possible dans ce délai. Veuillez sélectionner une autre date de montage.",
        "EVENT_NOT_POSSIBLE": "La durée maximale de location de l'électricité pour les événements est de 15 jours, celle-ci et a été dépassée. Veuillez choisir une période de location plus courte ou l'électricité de chantier.",
        "EXPRESS": "Il s’agit d’une commande express associée à un coût de 550 francs.",
        "NOTE": "Veuillez vous assurer que l’installatrice-électricienne ou l’installateur-électricien sous concession dépose l’avis d’installation sans tarder. La date de référence pour le montage est la date de réception de l’avis d’installation auprès du service de raccordement de BKW.",
      },
      "NOTE": "REMARQUE ",
    },
    "MONTAGE_DATE_HINT": "Les commandes dont la date de montage tombe au cours des quatre prochains jours ouvrables sont majorées d’un supplément express de 550 francs.",
    "SELECT_PRODUCT": "Veuillez sélectionner l’un de nos produits ou indiquer votre intensité de courant individuelle. *",
    "PRODUCT_DESCRIPTION": "Nos conditions concernant ",
    "PRODUCT_INFORMATION_URL": "http://www.bkw.ch/tempo",
    "PRODUCT_INFORMATION_TEXT": "les produits",
    "AND": "ainsi que celles concernant",
    "PRODUCT_INFORMATION_TARIFF_URL": "http://www.bkw.ch/tarife",
    "PRODUCT_INFORMATION_TARIFF_TEXT": "les tarifs de l’électricité et du réseau dans l’approvisionnement de base s’appliquent",
  },
  "CONSTRUCTION_ADDRESS_FORM": {
    "ADDRESS": "Adresse *",
    "ADDRESS_TYPES": {
      "ADDRESS_KNOWN": "L’adresse du chantier est connue",
      "ADDRESS_NOT_KNOWN": "Aucune adresse précise ne peut être affectée au chantier",
      "EVENT_ADDRESS_KNOWN": "L’adresse d'événement est connue",
      "EVENT_ADDRESS_NOT_KNOWN": "Aucune adresse précise ne peut être affectée à un événement",
    },
    "PLACEHOLDERS": {
      "PROJECT_NUMBER": "Numéro de commission/projet",
      "STREET": "Rue",
      "NUMBER": "N°",
      "ZIP": "NPA",
      "CITY": "Localité",
      "PARCEL": "Numéro de parcelle",
    },
    "PROJECT_NUMBER_TOOLTIP": "Le numéro de commission/projet permet de garantir la correspondance claire entre la demande et la commande de l’électricité de chantier et événementielle. \n\n peut par exemple s’agir d’un numéro de chantier, d’OTP ou d’offre de l’entrepreneur. Il est imprimé sur la facture pour que l’affectation soit correcte.",
    "NO_ADDRESS": "Aucune adresse précise ne peut être affectée au chantier.",
    "NO_EVENT_ADDRESS": "Aucune adresse précise ne peut être affectée à un événement.",
  },
  "POWER_CREATION_STEPPER": {
    "TITLE": "Formulaire de demande",
    "PRODUCT": {
      "CHOOSE_PRODUCT": "Veuillez sélectionner le produit souhaité et remplir les cases. Une fois clôturé, le formulaire de demande sera transmis à votre installatrice-électricienne ou à votre installateur-électricien sous concession qui remplira l’avis d’installation et l’enverra du service de raccordement de BKW.",
      "CONSTRUCTION": "Pour des projets de construction",
      "CONSTRUCTION_DESCRIPTION": "Électricité temporaire pour des projets de construction en tous genres, qu’il s’agisse d’une maison individuelle ou de gros chantiers.",
      "EVENT": "Pour des événements",
      "EVENT_DESCRIPTION": "Électricité temporaire pour des fêtes telles que des fêtes de village, des fêtes de vignerons, des fêtes de lutte suisse, des fêtes de gymnastique ou d’autres manifestations.",
    },
    "LABELS": {
      "CLIENT": "Mandant",
      "HINT": "Veuillez noter qu'un coffret de chantier ne peut plus être transférée à un autre client une fois qu'il a été installée. La responsabilité incombe au mandant jusqu'au démontage du coffret de chantier.",
      "INSTALLER": "Installatrice-électricienne ou installateur-électricien sous concession",
      "CONSTRUCTION_ADDRESS": "Adresse du chantier",
      "EVENT_ADDRESS": "Adresse de l'événement",
      "CONNECTION_DETAILS": "Détails du raccordement",
      "COMPLETION": "Clôture",
    },
    "BUTTONS": {
      "SEND": "Envoyer la demande",
      "SAVE": "Enregistrer",
    },
    "CONNECTION_DETAILS": {
      "MONTAGE_DATE": "Date de montage souhaitée",
      "DEMONTAGE_DATE": "Date de démontage prévue",
      "OTHER_AMPERAGE": "Intensité de courant souhaitée en ampères",
      "PROVISIONALLY": "Raccordement souhaité: station transformatrice mobile",
      "PRODUCT_COUNT": "Nombre: ",
    },
    "INFORMATION": "Renseignements concernant le projet de construction ou l’événement",
    "AGB_TEXT_1": "J’accepte les conditions contractuelles générales",
    "AGB_URL": "https://www.bkw.ch/fileadmin/user_upload/4_Ueber_BKW/Downloadcenter/AGB/Netz_und_Energie/AGB_Endverbraucher_NetzanschlussNetznutzung_A4_F_BKW_Web.pdf",
    "AGB_TEXT_2": "Raccordement au réseau et utilisation du réseau",
    "AGB_TEXT_3": ". J’ai pris connaissance des prescriptions d’entreprise BE/JU/SO (",
    "AGB_URL_2": "https://www.werkvorschriften.ch",
    "AGB_TEXT_4": "www.werkvorschriften.ch",
    "AGB_TEXT_5": ") qui y sont évoquées.",
    "NOTE": {
      "ALL" : "Malheureusement, nous ne pouvons pas envoyer la demande parce qu'il manque des informations qui sont obligatoires. Veuillez remplir les champs suivants, puis appuyez à nouveau sur le bouton “Envoyer la demande“.",
      "ALREADY_COMPLETED": "La demande a déjà été envoyée. Aucune autre modification ne peut être apportée.",
      "STEP_1": "Étape 1:",
      "STEP_2": "Étape 2: installateurs-électriciens sous concession",
      "STEP_3": "Étape 3:",
      "STEP_4": "Étape 4: détails du raccordement",
      "STEP_5": "Dernière étape: clôture",
      "INVALID_AGB": "Veuillez accepter nos CCG.",
      "ADDRESS" : {
        "PROJECT_NUMBER" : "Le numéro du projet",
        "STREET" : "La rue",
        "NUMBER" : "Le numéro",
        "POSTALCODE" : "Le NPA",
        "CITY" : "La localité",
        "PARCEL_NUMBER" : "Le numéro de parcelle",
      },
      "INSTALLER": {
        "NO_INSTALLER_CHOSEN" : "Aucune installatrice-électricienne ou aucun installateur-électricien sélectionné!",
        "COMMISION_NUMBER" : "Le numéro I",
        "COMPANY" : "L’entreprise",
        "FIRSTNAME" : "Le prénom",
        "LASTNAME" : "Le nom ",
        "TELEPHONE" : "Le numéro de téléphone",
        "EMAIL" : "L’adresse e-mail",
      },
      "AUDITOR" : {
        "COSTUMER_NUMBER" : "Le numéro de client du destinataire de la facture",
        "COMPANY" : "L’entreprise du destinataire de la facture",
        "SALUTATION" : "La civilité du destinataire de la facture",
        "FIRSTNAME" : "Le prénom du destinataire de la facture",
        "LASTNAME" : "Le nom du destinataire de la facture",
        "STREET" : "La rue du destinataire de la facture",
        "HOUSENUMBER" : "Le numéro de maison du destinataire de la facture",
        "POSTALCODE" : "Le NPA du destinataire de la facture",
        "CITY" : "La localité du destinataire de la facture",
        "TELEPHONE" : "Le numéro de téléphone du destinataire de la facture",
        "EMAIL" : "L’adresse e-mail du destinataire de la facture",
      },
      "OWNER" : {
        "COSTUMER_NUMBER" : "Le numéro de client du propriétaire",
        "COMPANY" : "L’entreprise du propriétaire",
        "SALUTATION" : "La civilité du propriétaire",
        "FIRSTNAME" : "Le prénom du propriétaire",
        "LASTNAME" : "Le nom du propriétaire",
        "STREET" : "La rue du propriétaire",
        "HOUSENUMBER" : "Le numéro de maison du propriétaire",
        "POSTALCODE" : "Le NPA du propriétaire",
        "CITY" : "La localité du propriétaire",
        "TELEPHONE" : "Le numéro de téléphone du propriétaire",
        "EMAIL" : "L’adresse e-mail du propriétaire",
      },
      "CONSTRUCTIONCONTACT" : {
        "OF_CONSTRUCTION" : "de la personne responsable du chantier",
        "OF_EVENT" : "de la personne responsable de l'événement",
        "COSTUMER_NUMBER" : "Le numéro de client ",
        "COMPANY" : "L’entreprise",
        "SALUTATION" : "La civilité",
        "FIRSTNAME" : "Le prénom",
        "LASTNAME" : "Le nom",
        "STREET" : "La rue",
        "HOUSENUMBER" : "Le numéro de maison",
        "POSTALCODE" : "Le NPA",
        "CITY" : "La localité",
        "TELEPHONE" : "Le numéro de téléphone",
        "EMAIL" : "L’adresse e-mail",
      },
      "PRIMARYCONTACT" : {
        "COSTUMER_NUMBER" : "Le numéro de client du mandant",
        "COMPANY" : "L’entreprise du mandant",
        "SALUTATION" : "La civilité du mandant",
        "FIRSTNAME" : "Le prénom du mandant",
        "LASTNAME" : "Le nom du mandant",
        "STREET" : "La rue du mandant",
        "HOUSENUMBER" : "Le numéro de maison du mandant",
        "POSTALCODE" : "Le NPA du mandant",
        "CITY" : "La localité du mandant",
        "TELEPHONE" : "Le numéro de téléphone du mandant",
        "EMAIL" : "L’adresse e-mail du mandant",
      },
      "CONNECTIONDETAILS": {
        "INSTALL_DATE": "La date du montage n’a pas encore été fixée!",
        "DEMONTAGE_DATE": "La date du démontage n’a pas encore été fixée!",
        "PRODUCT": "Aucun produit sélectionné!",
        "INVALID_DATE": "La date du montage ou du démontage non valable!",
      },
    },
  },
  "PRINT_STEP": {
    "LABEL": "Imprimer le formulaire de demande",
    "HAS_EMAIL": "Vous pouvez imprimer le formulaire de demande. Nous vous le conseillons si vous souhaitez archiver les demandes dans un classeur ou les remettre à votre installateur-électricien sous concession.",
    "NO_EMAIL": "Vous n’avez consigné aucune adresse e-mail pour votre installateur-électricien sous concession. Nous ne pouvons donc pas leur envoyer la demande par e-mail. Veuillez remettre la demande dûment remplie à votre installateur-électricien sous concession.",
    "BUTTONS": {
      "PRINT": "Imprimer",
      "CLOSE": "Fermer",
    },
  },
  "ORDER_OVERVIEW": {
    "TITLE": "Aperçu des demandes d’électricité temporaire",
    "NEW_ORDER": "Remplir un nouveau formulaire de demande",
    "SEARCH_PLACEHOLDER": "Recherche",
    "SEARCH_HINT": "Veuillez appuyer sur l'icône de recherche ou sur la touche ENTER pour lancer la recherche.",
    "SEARCH_HINT_2": "Utilisez des guillemets pour effectuer une recherche exacte, par exemple \"Exemple de rue 21\".",
    "SELECT_PLACEHOLDER": "Produit",
    "SELECT_OPTIONS": {
      "ALL": "Tous",
      "CONSTRUCTION": "Électricité du chantier",
      "EVENT": "Électricité de l'événement",
    },
    "DOWNLOAD_AS": "Télécharger sous forme de",
    "CSV": "csv",
    "CSV_FILE_NAME": "Demandes d’électricité temporaire_",
    "IN_PROCESSING": "en cours de traitement",
    "REQUESTED": "demandé",
    "COLUMN_TITLES": {
      "CLIENT": "Mandant",
      "PROJECT_NUMBER": "Numéro de commission/projet",
      "PRODUCT": "Produit",
      "INSTALLER": "Installatrice-électricienne ou installateur-électricien sous concession",
      "ASSEMBLY_DATE": "Date de montage",
      "DISASSEMBLY_DATE": "Date de démontage",
      "ORDER_STATUS": "Statut de la demande",
    },
    "BUTTONS": {
      "COMPLETE_APPLICATION": "Clôturer la demande",
      "APPLICATION_DETAILS": "Détail de la demande",
    },
  },
  "ORDER_DETAILS": {
    "TITLE": "Détail de la demande",
    "BACK_LINK": "« Retour à vos demandes",
    "ERROR": "Les détails de la demande que vous avez sollicités ne sont pas disponibles.",
  },
  "ORDER_DETAILS_TABLE": {
    "EDIT_MODE": "Mode d’édition",
    "HOVER_VIEW_DOCUMENT": "Aucun document n’a encore été téléchargé",
    "COLUMN_TITLES": {
      "DETAIL": "Détail",
      "STATUS": "Autres renseignements concernant le projet",
      "DATE": "Date",
      "RESPONSIBLE": "Responsable",
      "VIEW_DOCUMENT": "Visualiser<br>le document",
      "UPLOAD_DOCUMENT": "Télécharger<br>le document",
    },
    "ROW_NAMES": {
      "INSTALLATION_DISPLAY": "Dépôt de la demande d’avis d’installation auprès de votre installateur-électricien sous concession",
      "INSTALLATION_CONFIRMED_DISPLAY": "Dépôt de l’avis d’installation auprès du service de raccordement {{companyName}}",
      "ASSEMBLY_DATE": "Date du montage du coffret de chantier avec compte",
      "METER_BOX_LOCATION": "Emplacement du coffret de chantier avec compteur",
      "FINAL_CONTROL": "Contrôle final<br>rapport de sécurité assorti d’un procès-verbal de mesure et d’essai",
      "LOCATION_PARCEL": "Numéro de parcelle",
      "LOCATION_PLAN": "Plan de situation",
      "LOCATION_ADDRESS": "Adresse du chantier",
      "EVENT_LOCATION_ADDRESS": "Adresse de l'événement",
      "ACCEPTANCE_CONTROL": "Contrôle de réception",
      "DISASSEMBLY_DATE": "Date du démontage du coffret de chantier avec compteur <br> <br>" +
          "Important! Cette confirmation déclenche l'envoi d'un e-mail à votre électricien, <br>" +
          " qui doit maintenant initier la démontage auprès de BKW par Elektro-Form",
      "AUDITOR": "Le destinataire de la facture",
      "CLIENT": "Client",
      "CREATED_BY": "Rempli par",
      "LAST_MODIFIED_BY": "Modifié pour la dernière fois par",
      "INSTALLER": "Installatrice-électricienne ou<br>installateur-électricien sous concession",
      "EVENT_SITE_MANAGER": "Personne responsable de l'événement",
      "SITE_MANAGER": "Personne responsable du chantier",
      "OWNER": "Propriétaire",
      "SERIAL_NUMBER": "Référence du coffret de chantier avec compteur",
    },
    "NOTIFICATION_MESSAGES": {
      "UPDATED": "Vous avez bien modifié la date. Veuillez noter que la nouvelle date n’est pas encore confirmée.",
      "CONFIRMED": "Vous avez bien confirmé la date",
    },
    "STATUSES": {
      "PENDING": "Confirmation de la date<br>en suspens",
      "PENDING_2": "pas encore reçu",
      "CONFIRMED": "Date envoyée à l’installateur-électricien",
      "CONFIRMED_2": "déposé",
    },
  },
  "EDIT_LOCATION_MAP_DIALOG": {
    "PLOT_NUMBER": "N° de parcelle",
    "UPLOAD_LOCATION_MAP": "Téléchargement du plan de situation de la parcelle",
  },
  "EDIT_ADDRESS_DIALOG": {
    "TITLE": "Adresse - ",
    "INSTALLER": "Installatrice-électricienne ou installateur-électricien sous concession",
  },
  "EDIT_CONSTRUCTION_POWER_BOX_DIALOG": {
    "SERIAL_NUMBER": "Référence/numéro de série du coffret de chantier avec compteur",
  },
  "EDIT_DATE_DIALOG": {
    "TITLES": {
      "FINAL_CONTROL": "Contrôle final, rapport de sécurité assorti d’un procès-verbal de mesure et d’essai",
      "ACCEPTANCE_CONTROL": "Contrôle de réception",
      "INSTALLATION_CONFIRMED_DISPLAY": "Dépôt de l’avis d’installation",
    },
    "PLACEHOLDERS": {
      "FINAL_CONTROL": "Date du contrôle final",
      "ACCEPTANCE_CONTROL": "Date du contrôle de réception",
      "INSTALLATION_CONFIRMED_DISPLAY": "Date de dépôt auprès du service de raccordement",
    },
  },
  "EDIT_DEMONTAGE_DATE_DIALOG": {
    "TITLE": "Confirmer la date",
    "TEXT": "Veuillez confirmer la date suivante ou la modifier. Vous pouvez choisir une nouvelle date dans dix jours ouvrables au plus tôt.",
    "CONFIRMATION": "Je confirme par la présente qu’il s’agit de la date de démontage définitive du coffret de chantier avec compteur.",
    "BUTTONS": {
      "UPDATE": "Actualiser",
      "CONFIRM": "Confirmer",
    },
  },
  "FILE_UPLOAD_COMPONENT": {
    "UPLOAD": "Téléchargement du plan de situation de la parcelle",
    "SUCCESS": "Le fichier peut être téléchargé.",
    "ERROR": "Erreur lors de la lecture ou de la validation du fichier. Seules les images (JPG/JPEG et PNG) ou les PDF sont autorisés et la taille maximale des fichiers est de 2 MB!",
  },
  "PDF": {
    "FILE_NAME": "_Détail de la demande d’électricité temporaire",
    "EXPRESS": "Express:",
    "TITLE": "Formulaire de demande ({{additionalData}}) d’électricité temporaire,",
    "NOTE": "* La demande express doit être traitée sans tarder pour garantir une exécution conforme au délai.",
    "TABLE": {
      "HEADERS": {
        "CLIENT": "Mandant",
        "EVENT_SITE_MANAGER": "Personne responsable de l'événement",
        "SITE_MANAGER": "Personne responsable du chantier",
        "AUDITOR": "Le destinataire de la facture",
        "OWNER": "Propriétaire",
        "INSTALLER": "Installatrice-électricienne ou installateur-électricien sous concession",
        "PROJECT_ADDRESS": "Adresse du chantier",
        "EVENT_ADDRESS": "Adresse de l'événement",
        "LOCATION_MAP": "Plan de situation de la parcelle",
        "CONNECTION_DETAILS": "Détails du raccordement",
      },
      "ASSEMBLY_DATE": "Date de montage souhaitée:",
      "DISASSEMBLY_DATE": "Date de démontage prévue:",
      "PRODUCT": "Produit:",
      "PRODUCT_COUNT": "Nombre:",
      "COMMISSION_NUMBER": "Numéro de commission/projet:",
      "STREET_NUMBER": "Rue et n°:",
      "ZIP_CITY": "NPA, localité:",
      "PLOT_NUMBER": "Numéro de parcelle:",
      "I_NUMBER": "Numéro d’autorisation:",
      "REPLACEMENT_LICENSE": "Autorisation intérimaire:",
      "COMPANY": "Société:",
      "NAME": "Nom:",
      "PHONE": "Tél:",
      "EMAIL": "E-Mail:",
      "JA": "Oui",
      "NEIN": "Non",
    },
  },
  "TEMPORARY_TRANSFORMER_STATION": "Station transformatrice provisoire",
};
